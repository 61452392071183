<template>
  <div class="home">
    <div class="title">插件助手</div>
    <el-row class="row-name" :gutter="50">
      <el-col :span="6">
        <div class="left">
          <div class="line">
            <div class="name">作者：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.author" placeholder="作者"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">漏洞名：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.name" placeholder="漏洞名"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">参考：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.reference" placeholder="漏洞参考的网址"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">CMS：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.service" placeholder="通常为cms名称 如dedecms"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">目标：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.target" placeholder="测试的网址 如http://qq.com/"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">Payload：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.payload" placeholder="如index.php?id=1 and 1=1"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">字符/正则：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.flag" placeholder="返回内容的关键字"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">RetCode：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.retcode" placeholder="HTTP返回状态码"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">Headers：</div>
            <div class="input">
              <el-input @input="Update" v-model="form.headers" placeholder="Headers"></el-input>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="7">
        <div class="model">
          <div class="line2">
            <div class="left">
              <div class="name">漏洞级别</div>
              <div class="input">
                <el-radio-group @input="Update" v-model="form.lv">
                  <el-radio label="lv0">note</el-radio>
                  <el-radio label="lv1">info</el-radio>
                  <el-radio label="lv2">warning</el-radio>
                  <el-radio label="lv3">hole</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="right">
              <div class="name">判断方式</div>
              <div class="input">
                <el-radio-group @input="Update" v-model="form.flagType">
                  <el-radio label="flag1">字符串</el-radio>
                  <el-radio label="flag2">正则</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line3">
            <div class="name">发送方式：</div>
            <div class="input">
              <el-radio-group @input="Update" v-model="form.curlType">
                <el-radio label="curlType0">Post/Cookies</el-radio>
                <el-radio label="curlType">Raw</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line">
            <div class="name">Post:</div>
            <div class="input">
              <el-input @input="Update" v-model="form.post" placeholder="Post"></el-input>
            </div>
          </div>
          <div class="line">
            <div class="name">Cookies:</div>
            <div class="input">
              <el-input @input="Update" v-model="form.Cookies" placeholder="Cookies"></el-input>
            </div>
          </div>
          <div class="line1">
            <div class="name">Raw:</div>
            <div class="input">
              <el-input @input="Update" type="textarea" :autosize="{ minRows: 10, maxRows: 10 }"
                placeholder="从burp中复制过来" v-model="form.raw">
              </el-input>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="11">
        <div class="right">
          <el-input type="textarea" spellcheck="false" readonly :autosize="{ minRows: 23 }" class="ng-binding"
            placeholder="" v-model="form.finalCode">
          </el-input>
          <div class="btn-box">
            <el-button type="primary" round @click="copycode">应用到插件添加</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  name: 'HomeView',
  data() {
    return {
      form: {
        author: '',
        name: '',
        reference: '',
        service: '',
        target: '',
        payload: '',
        headers: '',
        flag: '',
        retcode: '',
        lv: 'lv0',
        flagType: 'flag1',
        curlType: 'curlType0',
        post: '',
        Cookies: '',
        raw: '',
        finalCode: ''
      }
    }
  },
  created() {
    this.Update()
  },
  methods: {
    copycode() {
      let data = this.form.finalCode
      const blob = new Blob([data], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.form.name? this.form.name + '.py' : 'output.py';
      a.click();
    },
    Trans(s) {
      let ret = s.replace(/\'/g, '\\\'');
      return ret.replace(/\"/g, '\\\"');
    },
    Update() {
      let val = "#!/usr/bin/env python\n# -*- coding: utf-8 -*-\n# 该插件使用插件助手生成\n**author****plugName****refer**import re**headers**\ndef assign(service, arg):\n    if service == '**service**':\n        return True, arg\ndef audit(arg):\n    payload = '**payload**'\n    target = arg + payload\n    **raw1**\n    code, head, res, final_url, log= mm.http(target**post****cookies****raw2**)\n    if **retCode****flag**:\n        security_**lv**(arg,**name**target**lv_than_warning**)\nif __name__ == '__main__':\n    import core \n    mm = core.BwwHttp()\n    audit(assign('**service**', '**targetUrl**')[1])"

      // 作者
      if (this.form.author != "")
        val = val.replace("**author**", "# 作者 = " + this.form.author + "\n");
      else
        val = val.replace("**author**", "");
      // 目标
      if (this.form.target != "")
        val = val.replace("**targetUrl**", this.form.target);
      else
        val = val.replace("**targetUrl**", "");
      // 名称
      if (this.form.name != "")
        val = val.replace("**plugName**", "# 插件名 = " + this.form.name + "\n");
      else
        val = val.replace("**plugName**", "");
      // 名称2
      if (this.form.name) {
        val = val.replace("**name**", "\""+this.form.name + "\",");
      } else {
        val = val.replace("**name**", "");
      }
      // 漏洞来源
      if (this.form.reference != "")
        val = val.replace("**refer**", "# 漏洞来源 = " + this.form.reference + "\n");
      else
        val = val.replace("**refer**", "");
      // 服务1
      if (this.form.service) {
        val = val.replace("**service**", this.form.service);
      } else {
        val = val.replace("**service**", "");
      }
      // 服务2
      if (this.form.service) {
        val = val.replace("**service**", this.form.service);
      } else {
        val = val.replace("**service**", "");
      }

      // Payload
      if (this.form.payload != "")
        val = val.replace("**payload**", this.Trans(this.form.payload));
      else
        val = val.replace("**payload**", "");
      // Headers
      if (this.form.headers != "")
        val = val.replace("**headers**", '\nheaders={'+this.form.headers+'}');
      else
        val = val.replace("**headers**", "");
      // 发送方式
      if (this.form.curlType == 'curlType0' && this.form.post != "")
        val = val.replace("**post**", ", data=\"" + this.Trans(this.form.post) + "\"");
      else
        val = val.replace("**post**", "");
      if (this.form.curlType == 'curlType0' && this.form.Cookies != "")
        val = val.replace("**cookies**", ", cookie=\"" + this.Trans(this.form.Cookies) + "\"");
      else
        val = val.replace("**cookies**", "");
      if (this.form.curlType == 'curlType' && this.form.raw != "") {
        val = val.replace("**raw1**", "raw=\"\"\"" + this.Trans(this.form.raw) + "\"\"\"");
        val = val.replace("**raw2**", ", raw=raw");
      } else {
        val = val.replace("**raw1**", "");
        val = val.replace("**raw2**", "");
      }
      // RetCode
      if (this.form.retcode != "")
        val = val.replace("**retCode**", "code == " + this.form.retcode);
      else
        val = val.replace("**retCode**", "");
      // 字符串 或 正则
      if (this.form.flagType == 'flag1')
        if (this.form.flag != "") { //Flag:
          if (this.form.retcode != "")
            val = val.replace("**flag**", " and \"" + this.Trans(this.form.flag) + "\" in res");
          else
            val = val.replace("**flag**", "\"" + this.Trans(this.form.flag) + "\" in res");
        }
        else {
          val = val.replace("**flag**", "");
        }
      else if (this.form.flag != "") {
        if (this.form.retcode != "")
          val = val.replace("**flag**", " and re.search(\"" + this.Trans(this.form.flag) + "\", res)");
        else
          val = val.replace("**flag**", "re.search(\"" + this.Trans(this.form.flag) + "\", res)");
      }
      else val = val.replace("**flag**", "");

      // 漏洞级别
      if (this.form.lv == 'lv0') {
        val = val.replace("**lv**", "note");
        val = val.replace("**lv_than_warning**", "");
      }
      else if (this.form.lv == 'lv1') {
        val = val.replace("**lv**", "info");
        val = val.replace("**lv_than_warning**", "");
      }
      else if (this.form.lv == 'lv2') {
        val = val.replace("**lv**", "warning");
        val = val.replace("**lv_than_warning**", "");
      }
      else {
        val = val.replace("**lv**", "hole");
        val = val.replace("**lv_than_warning**", "");
      }
      this.form.finalCode = val;
    }
  }
}
</script>
<style scoped lang="scss">
.row-name {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.home {
  width: 100%;
  height: 100%;
  background-color: #f4f3ef;
  padding: 20px;
  color: #333;

  .btn-box {
    margin-top: 20px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }

  .line {
    border-top: 2px solid #e7e6e2;
    line-height: 80px;
    display: flex;

    .name {
      width: 100px;
      font-weight: bold;
    }

    .input {
      flex: 1;
    }
  }

  .line1 {
    border-top: 2px solid #e7e6e2;
    line-height: 200px;
    padding-top: 20px;
    display: flex;

    .name {
      width: 100px;
      font-weight: bold;
    }

    .input {
      flex: 1;
    }
  }

  .line2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .name {
      margin-bottom: 20px;
      font-weight: bold;
    }

    .right {
      .name {
        text-align: right;
        padding-right: 15px;
      }

    }

    .input {
      width: 100px;
      line-height: 50px;

      ::v-deep(.el-radio) {
        margin-bottom: 15px;
      }
    }
  }


  .line3 {
    margin-bottom: 20px;

    .name {
      margin-bottom: 20px;
      font-weight: bold;
    }
  }

  .ng-binding {
    resize: none;
    margin-top: 24px;
    width: 100%
  }
}
</style>
